<template>
  <div class="app">
    <div v-for="(item, index) in dataarr" :key="index">
      <div
        :class="item.sele == 1 ? 'station-item' : 'station-item1'"
        @click="handelExpand(index)"
      >
        <div class="box"></div>
        <div class="station_tit">
          <p>{{ item.title }}</p>
          <i v-if="item.sele == 0" class="el-icon-arrow-right"></i>
          <i v-if="item.sele == 1" class="el-icon-arrow-down"></i>
        </div>
        <div class="station_data">
          <p>任职要求：</p>
          <ul v-for="(item1, index1) in item.data" :key="index1">
            <li>{{ item1.requirement }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "more",
  // 组件参数 接收来自父组件的数据
  props: {},

  // 组件状态值
  data() {
    return {
      Selected: 1,
      dataarr: [
        {
          title: "中央空调设计师（暖通设计师、暖通工程师）",
          sele: 0,
          data: [
            {
              requirement:
                "1、建筑环境与设备工程、建筑环境与能源动力工程、供暖、供燃气及通风工程、制冷、空调等相关专业，全日制大专及以上学历，经验丰富者可放宽学历；",
            },
            {
              requirement:
                "2、熟悉中央空调运行原理及系统设计，具备本专业的基本理论知识；",
            },
            {
              requirement:
                "3、能够操作天正暖通、CAD、Word、Excel等相关计算机软件；",
            },
            { requirement: "4、有2年以上相关工作经验者优先录用。" },
          ],
        },

        {
          title:
            "嵌入式软硬件开发工程师（嵌入式软件助理工程师、单片机硬件开发工程师、嵌入式开发工程师 ）",
          sele: 0,
          data: [
            {
              requirement:
                "1、电子信息工程、自动化、物联网，计算机等相关专业，大专及以上学历；",
            },
            { requirement: "2、具有2年以上的相关工作经验；" },
            { requirement: "3、熟练掌握C语言编程技巧；" },
            { requirement: "4、熟练使用一种EDA工具，如kicad，ad等；" },
            {
              requirement:
                "5、熟练掌握硬件调试技巧，能够独立分析和解决软硬件出现的问题；",
            },
            { requirement: "6、熟练使用常见接口，如uart，spi，iic等" },
            { requirement: "7、有物联网项目经验优先。" },
            {
              requirement:
                "8、有责任心、上进心，具有较强的团队沟通能力和学习能力；",
            },
          ],
        },

        {
          title: "PLC工程师",
          sele: 0,
          data: [
            {
              requirement:
                "1. 电气自动化、测控技术等相关专业，大专及以上学历；",
            },
            { requirement: "2、熟悉电气自动化控制原理；" },
            {
              requirement:
                "3、具备自动化领域相关技能，如电气设计、主流品牌PLC编程（西门子、三菱）、人机界面组态；",
            },
            { requirement: "4、熟悉仪器仪表、现场总线、网络通信等；" },
            { requirement: "5、具有2年以上电气自动化项目全流程实施工作经验；" },
            { requirement: "6、能够适应中短期出差。" },
          ],
        },
        {
          title: "采购专员",
          sele: 0,
          data: [
            {
              requirement: "1、大专及以上学历；",
            },
            {
              requirement:
                "2、具有3年以上从事建材、电子元器件或电气元件的采购经验；",
            },
            {
              requirement: "3、能够熟练操作Word、Excel等办公软件；",
            },
            { requirement: "4、有采购管理经验者优先录用。" },
          ],
        },
        {
          title: "财务主管 ",
          sele: 0,
          data: [
            {
              requirement: "1、财会等相关专业，大专及以上学历；",
            },
            {
              requirement: "2、有中级职称或者注册会计师资格者优先;",
            },
            {
              requirement: "3、5年以上会计工作经验；",
            },
            {
              requirement: "4、熟悉用友财务软件；",
            },
            {
              requirement: "5、一般纳税人经验；",
            },
            {
              requirement: "6、熟悉财务核算流程，有不断学习的意愿和能力;",
            },
            {
              requirement:
                "7、有良好的沟通和人际交往能力，组织协调能力和承压能力；",
            },
            {
              requirement:
                "8、男女不限，对财务部日常管理，对内对外进行良性沟通。",
            },
          ],
        },

        {
          title: "新媒体文案专员",
          sele: 0,
          data: [
            {
              requirement: "1、全日制大专及以上学历；",
            },
            {
              requirement: "2、具有3年以上文案撰写、媒体策划等相关工作经验；",
            },
          ],
        },

        {
          title: "机械设计工程师",
          sele: 0,
          data: [
            { requirement: "1、5年以上的机械类产品设计工作经验；" },
            {
              requirement:
                "2、具备一定的机械设计专业知识，有制作烘干机方面经验优先考虑；",
            },
            { requirement: "3、熟练操作计算机常用软件和制图软件；" },
            { requirement: "4、具有良好的工作态度，沟通学习能力；" },
          ],
        },

        {
          title: "电气工程师",
          sele: 0,
          data: [
            {
              requirement: "1、电气自动化等相关专业，大专及以上学历",
            },
            { requirement: "2、熟悉西门子200、1200等主流编程。" },
            { requirement: "3、熟悉现场安装、调试。" },
            { requirement: "4、有团队管理经验者优先。" },
          ],
        },

        {
          title: "市场策划（营销经理）",
          sele: 0,
          data: [
            {
              requirement: "1、大专以上学历，能力优秀者可适当放宽；",
            },
            {
              requirement:
                "2、2年及以上营销经验，有中央空调、暖通行业营销推广经验者优先考虑；",
            },
            {
              requirement:
                "3、熟悉区域市场开拓和管理，有较强的区域市场开拓能力；",
            },
            { requirement: "4、能吃苦耐劳，服从公司管理，有较强的承受力。" },
          ],
        },

        {
          title: "行政人事 ",
          sele: 0,
          data: [
            {
              requirement:
                "1、人力资源、行政管理等相关专业，3年以上人力资源或行政工作经验；",
            },
            {
              requirement: "2、熟练使用计算机办公软件；",
            },
            {
              requirement: "3、具有良好的沟通能力、协调能力。",
            },
          ],
        },
      ],
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  computed: {},
  destroyed() {},
  // 组件方法
  methods: {
    handelExpand(index) {
      if (this.dataarr[index].sele == 1) {
        this.dataarr[index].sele = 0;
      } else {
        this.dataarr[index].sele = 1;
      }
    },
  },
};
</script>

<style scoped lang="less">
ul li {
  list-style-type: none;
}
.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.1px;
  margin-bottom: 10px;
  //   border: 1px solid gold;
  .station-item {
    width: 100%;
    min-height: 45px;
    margin-top: 20px;
    .station_tit {
      width: 100%;
      height: 45px;
      background-color: #005ba8;
      color: #e6e6eb;
      font-size: 17px;
      font-weight: 600;
      line-height: 45px;
      text-indent: 20px;
      display: flex;
      p {
        width: 900px;
        // border: 1px solid gold;
      }
      i {
        margin-left: 7%;
        line-height: 45px;
      }
    }
    .station_data {
      width: 100%;
      min-height: 50px;
      p {
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #005ba8;
        font-size: 15px;
        text-indent: 20px;
        margin: 0 auto;
        // border: 1px solid gold;
      }
      ul li {
        width: 97%;
        margin: 0 auto;
        font-size: 15px;
        //   border: 1px solid gold;
      }
      li {
        margin-top: 10px !important;
      }
    }
  }
  .station-item1 {
    width: 100%;
    min-height: 45px;
    .station_tit {
      width: 100%;
      height: 45px;
      margin-top: 20px;
      background-color: #e6e6eb;
      font-size: 17px;
      font-weight: 600;
      line-height: 45px;
      text-indent: 20px;
      display: flex;
      p {
        width: 900px;
      }
      i {
        margin-left: 7%;
        line-height: 45px;
      }
    }
    .station_data {
      width: 100%;
      min-height: 50px;
      display: none;
      //   border: 1px solid gray;
    }
  }
}
</style>
