<template>
  <div class="app">
    <div class="box-top">
      <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-message"></i>
          <p>邮箱</p>
        </div>
        <div class="box-on-cen">
          有意者请先投递简历或将简历发至邮箱：gaoyang@hnyici.cn，请按以下标准填写邮件主题：应聘/**（职位）/***（姓名）。
        </div>
      </div>
      <div class="box-cen" style="width: 10%"></div>
      <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-user"></i>
          <p>面试邀请</p>
        </div>
        <div class="box-on-cen">
          接到面试通知后，请应聘者按照通知的时间携带本人简历、毕业证复印件、身份证复印件及其他相关证件复印件到公司面试，一经录用，待遇从优。
        </div>
      </div>
    </div>
    <div class="box-top">
      <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-phone-outline"></i>
          <p>电话</p>
        </div>
        <div class="box-on-cen">高女士：15638977583<br>田先生：15537776206</div>
      </div>
      <div class="box-cen" style="width: 10%"></div>
      <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-location-information"></i>
          <p>总部地址</p>
        </div>
        <div class="box-on-cen">
          南阳市高新区中关村光电产业B区羿赐智能科技（纬七路与蒲山路交叉口往东500 米路北）
        </div>
      </div>
    </div>
    <div class="box-top">
      <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-link"></i>
          <p>公司网址</p>
        </div>
        <div class="box-on-cen">www.hnyici.cn</div>
      </div>
      <div class="box-cen" style="width: 10%"></div>
      <!-- <div class="box-on">
        <div class="box-on-tit">
          <i class="el-icon-location-information"></i>
          <p>总部地址</p>
        </div>
        <div class="box-on-cen">
          南阳市高新区中关村光电产业B区羿赐智能科技（纬七路与蒲山路交叉口往东500 米路北）
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "more",
  // 组件参数 接收来自父组件的数据
  props: {},

  // 组件状态值
  data() {
    return {
      Selected: 1,
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  computed: {},
  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang="less">
.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.1px;
  //   border: 1px solid gold;
  .box-top {
    width: 100%;
    height: 180px;
    margin-top: 40px;
    display: flex;
    //   border: 1px solid gray;
    .box-on {
      width: 45%;
      height: 180px;
      //   border: 1px solid gold;
      .box-on-tit {
        width: 100%;
        height: 40px;
        display: flex;
        // border: 1px solid gray;
        .el-icon-message {
          font-size: 32px;
          line-height: 40px;
        }
        .el-icon-phone-outline {
          font-size: 32px;
          line-height: 40px;
        }
        .el-icon-user {
          font-size: 32px;
          line-height: 40px;
        }
        .el-icon-location-information {
          font-size: 32px;
          line-height: 40px;
        }
        .el-icon-link{
          font-size: 32px;
          line-height: 40px;
        }
        p {
          width: 20px;
          height: 25px;
          font-size: 17px;
          font-weight: 600;
          line-height: 25px;
          margin: auto 0;
          margin-left: 10px;
          white-space: nowrap;
          border-bottom: 3px solid #005ba8;
        }
      }
      .box-on-cen {
        width: 88%;
        height: 135px;
        line-height: 26px;
        // border: 1px solid gray;
        font-size: 14px;
        padding-top: 5px;
        padding-left: 40px;
      }
    }
  }
}
</style>
