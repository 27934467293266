<template>
  <div class="app">
    <div class="title">
      <div class="title_box">
        <p v-if="Selected == 1">招聘岗位</p>
        <p v-if="Selected == 0">如何应聘</p>
        <button
          :class="Selected == 1 ? 'button_xz' : 'button_wx'"
          @click="handelSwitch(1)"
        >
          招聘岗位
        </button>
        <button
          :class="Selected == 0 ? 'button_xz' : 'button_wx'"
          @click="handelSwitch(0)"
        >
          如何应聘
        </button>
      </div>
    </div>
    <div class="box">
      <div class="box_btm" v-if="Selected == 1">
        <Postdata></Postdata>
      </div>
      <div class="box_btm" v-if="Selected == 0">
        <Application></Application>
      </div>
    </div>
    <div class="box-btm"><Contact></Contact></div>
  </div>
</template>

<script>
import Contact from "../home/components/Contact.vue";
import Postdata from "../recruit/components/postdata.vue";
import Application from "../recruit/components/Application.vue";
import { contentList } from "../../assets/api/indexl";
export default {
  // 组件名称
  name: "more",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {
    Contact,
    Postdata,
    Application,
  },
  // 组件状态值
  data() {
    return {
      Selected: 1,
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    var currentURL = window.location.href;
    this.$emit("passfunction", currentURL);
  },
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  computed: {},
  destroyed() {},
  // 组件方法
  methods: {
    handelSwitch(type) {
      this.Selected = type;
    },
  },
};
</script>

<style scoped lang="less">
.app {
  width: 100%;
  height: 100%;
  // background-color: aquamarine;
  display: flex;
  flex-direction: column;
  padding: 0.1px;
  background-color: #f9f9f9;
}
.title {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid RGBA(52, 54, 60, 0.4);
  margin: 0px auto;
  margin-top: 140px;
  .title_box {
    width: 1040px;
    margin: 0px auto;
    display: flex;
  }
  p {
    height: 60px;
    width: 200px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 600;
    color: #005ba8;
    text-indent: 24px;
  }
  button:nth-child(2) {
    margin-left: 62%;
  }
  button:nth-child(3) {
    margin-left: 10px;
  }
  .button_xz {
    margin: auto 0;
    width: 80px;
    height: 36px;
    border: none;
    color: #f9f9f9;
    background-color: #005ba8;
    border-radius: 8px;
    font-size: 14px;
  }
  .button_wx {
    font-size: 14px;
    margin: auto 0;
    width: 80px;
    height: 36px;
    border: none;
    //   background-color: gray;
    border-radius: 8px;
  }
}
.box {
  width: 1040px;
  min-height: 720px;
  margin: 0px auto;
  margin-bottom: 100px;
}
.box-btm {
  width: 100%;
  height: 214px;
  background-color: black;
  margin-bottom: 0px;
  // position: absolute;
	// left: 0;
	// bottom: 0;
}
</style>
